import * as React from "react";
import {connect} from "react-redux";
import {navigate} from "gatsby";

// components
import DefaultLayoutComponent from "../components/layouts/DefaultLayoutComponent";
import {setConsequences} from "../redux/actions/global/setConsequences";
import {getResultSkeletonConfig} from "../config/ResultSkeletonConfig";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUndo} from "@fortawesome/free-solid-svg-icons";
import ModalComponent from "../components/ModalComponent";
import {StaticImage} from "gatsby-plugin-image";

const EndPage = ({isNewState, consequences, dispatch}) => {
    const [opponentsModalOpenState, setOpponentsModalOpenState] = React.useState(false);
    const [playerModalOpenState, setPlayerModalOpenState] = React.useState(false);

    React.useEffect(() => {
        if (isNewState) {
            navigate('/');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleOpponentsModal = () => {
        setOpponentsModalOpenState(!opponentsModalOpenState);
    };

    const togglePlayerModal = () => {
        setPlayerModalOpenState(!playerModalOpenState);
    };

    const handleSubmit = () => {
        dispatch(setConsequences(getResultSkeletonConfig()));
        if(consequences.endGameConsequences.redirectToGame != null) {
            navigate("/code", {replace: true});
        } else {
            navigate("/", {replace: true});
        }
    };

    return (
        <DefaultLayoutComponent>
            <h1 className="display-6 stone-light text-center px-3 mb-0">CAPAX IMPERI</h1>
            {consequences != null && consequences.endGameConsequences != null ?
             <div className="ready-wrapper text-center px-3">
                 <h2 className="display-4">FIN DU JEU</h2>
                 <p className="lead">{consequences.endGameConsequences.message}</p>
             </div>
             : false
            }
            <div className="row gx-0 px-3 text-center w-100 justify-content-around">
                <div className="col-4">
                    <button className="hk-btn hk-btn-light merites" onClick={() => togglePlayerModal()}>
                        <StaticImage src="../assets/images/merites.png" alt="" />
                    </button>
                    <p className="text-muted mb-0">
                        <small>Vos mérites</small>
                    </p>
                </div>
                <div className="col-4">
                    <button className="hk-btn hk-btn-light histoire" onClick={handleSubmit}>
                        <FontAwesomeIcon icon={faUndo} />
                    </button>
                    <p className="text-muted mb-0">
                        <small>Recommencer</small>
                    </p>
                </div>
                <div className="col-4">
                    <button className="hk-btn hk-btn-light adversaires" onClick={() => toggleOpponentsModal()}>
                        <StaticImage src="../assets/images/adversaires.png" alt="" />
                    </button>
                    <p className="text-muted mb-0">
                        <small>Adversaires</small>
                    </p>
                </div>
            </div>
            <ModalComponent modalId="opponentsModal" modalType="Opponents" fullscreen={true} open={opponentsModalOpenState}/>
            <ModalComponent modalId="playerModal" modalType="Player" fullscreen={true} open={playerModalOpenState}/>
        </DefaultLayoutComponent>
    )
};

export default connect(state => ({
    isNewState: state.global.codeList == null,
    consequences: state.global.consequences
}), null)(EndPage)
